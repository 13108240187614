import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import ExternalLink from '../styled/ExternalLink'

const Container = styled.div`
  margin-top: ${props => props.theme.margin.top};
  margin-left: ${props => props.theme.margin.left};
`

interface Props {
  location: Location
}

const NotFoundPage = (props: Props) => {
  const { location } = props
  return (
    <Layout>
      <SEO
        description="Page not found"
        disableIndexing
        pathname={location.pathname}
        title="Page not found"
      />

      <Container>
        <h1>404</h1>
        <ExternalLink href="/">Back home</ExternalLink>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
